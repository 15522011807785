import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import Layout from "../../components/layout"
import IntegrationAbout from "../../components/pages/features/integration-about"
import BannerComponent from "../../components/pages/features/banner"
import AdditionalComponent from "../../components/pages/features/additional-integration"
import FeaturesComponent from "../../components/pages/main/features"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import PricingComponent from "../../components/pages/features/pricing"
import styles from "./feature.module.scss"
import GalleryComponent from "../../components/pages/features/gallery"
import Discount from "../../components/pages/main/discount"

const IntegrationFeaturePage = (props) => (
  <Layout className={styles.integration} headerColor={"#319DED"} page={"integration"}>
    <SEO
      title="Интеграция | CRM-система учета для детских центров"
      description="Интеграции объединят сбор заявок, запись на занятия и общение с клиентами в одной системе. Делайте меньше переключений и больше успешных сделок."
      url={"https://paraplancrm.ru" + props.location.pathname}
    />
    <HeroFeaturePage
      name={"integration"}
      title='Подключите дополнительные сервисы'
      description='Интеграции объединят сбор заявок, запись на занятия и общение с клиентами в одной системе. Делайте меньше переключений и больше успешных сделок.'
      color='#319DED'
      image={require("../../images/cover/integration.png")} />
    <GalleryComponent
      title={"Интеграция с онлайн-кассой"}
      subtitle={"Формируйте чек после каждой финансовой операции и моментально отправляйте его покупателям и в налоговую."}
      galleryView={"img"}
      rightImage={true}
      offShadow={true}
      smallMargin={true}
      data={[
        {
          name: "cashreg",
          title: "Оформление чеков",
          content: "Создавайте чек после любой финансовой операции: покупка абонемента или пробного занятия, товара, оплата долга или возврат средств. Проводите кассовые операции в соответствии с Законом № 54-ФЗ. Подключите онлайн-кассу по <a href='https://modulkassa.pro/?utm_source=pap&a_aid=Paraplan' target='_blank'>ссылке</a>.",
          image: require("../../images/screenshot/integration/cash-reg2.png"),
        },
        {
          name: "cashsend",
          title: "Отправка чеков",
          content: "Отправляйте ученикам чек автоматически на email после пополнения баланса в Личном кабинете. Каждый пробитый чек мгновенно передается для учета в налоговую. Минимум бумажных документов.",
          image: require("../../images/screenshot/integration/cash-send2.png"),
        },
        {
          name: "cashhistory",
          title: "История чеков",
          content: "Находите нужные чеки во вкладке “Чеки” раздела “Финансы”. Быстро ищите документ по периоду. Вы всегда сможете продублировать чек клиенту, скачав его в онлайн-кассе.",
          image: require("../../images/screenshot/integration/cash-history2.png"),
        },
      ]}
    />
    <GalleryComponent
      title={"Интеграция с WhatsApp, Telegram"}
      subtitle={"Общайтесь с учениками, лидами, педагогами в одном окне CRM-системы."}
      galleryView={"img"}
      offShadow={true}
      smallMargin={true}
      data={[
        {
          name: "chat",
          title: "Внутренний чат",
          content: "Переписывайтесь с группами или отдельными учениками в чате CRM-системы. Чат обновляется онлайн и заменяет приложения WhatsApp и Telegram. Клиент не берет трубку - начните общение в мессенджере.",
          image: require("../../images/screenshot/integration/chat2.png"),
        },
        {
          name: "history",
          title: "История переписки",
          content: "Просматривайте историю сообщений для контроля вашего менеджера и построения эффективного диалога с клиентом. Лиды и ученики общаются привычным способом, при этом переписка дублируется в их профилях.",
          image: require("../../images/screenshot/integration/history2.png"),
        },
        {
          name: "bookings",
          title: "Уведомления в системе",
          content: "Сокращайте время ответа на сообщения благодаря уведомлениям в системе. Быстрое реагирование на запросы поможет больше продавать и удерживать клиентов центра. Сервис становится лучше, а работа - проще.",
          image: require("../../images/screenshot/integration/notifications2.png"),
        },
      ]}
    />
    <GalleryComponent
      title={"Интеграция с IP-телефонией Zadarma"}
      subtitle={"Получайте уведомления о входящих и исходящих вызовах, создавайте лиды из звонков или ставьте по ним задачи."}
      galleryView={"img"}
      rightImage={true}
      offShadow={true}
      smallMargin={true}
      data={[
        {
          name: "historyCalls",
          title: "История звонков",
          content: "Отслеживайте историю звонков, оценивайте их динамику в разделе “Связь”. Всегда под рукой история коммуникации с каждым лидом или учеником в его профиле.",
          image: require("../../images/screenshot/integration/historyCalls2.png"),
        },
        {
          name: "automatic",
          title: "Автоматические действия",
          content: "Получайте уведомления о входящих звонках на главном экране. Система определит ученика, если его номер есть в системе. Если нет - автоматически создаст лида в “Воронке продаж”.",
          image: require("../../images/screenshot/integration/automatic2.png"),
        },
        {
          name: "controlTeam",
          title: "Контроль сотрудников",
          content: "Назначайте ответственных за звонки. Проверяйте, кому и когда звонили, сколько длился разговор, выполнены ли задачи. Прослушивайте записи входящих и исходящих вызовов сразу в системе.",
          image: require("../../images/screenshot/integration/controlTeam2.png"),
        },
      ]}
    />
    <Discount />
    <IntegrationAbout 
      title="Готовые виджеты"
      subtitle="Автоматизируйте сбор лидов и запись на занятия с любого источника."
    />
    <BannerComponent color={"green"} author={"Игорь Софронюк, школа танцев «The NINE»"}>
      Лиды с формы записи автоматом добавляются в «Воронку продаж». С заявками очень удобно работать и доводить их до результата. Что особенно важно - все корректно работает на мобильной версии!
    </BannerComponent>
    <GalleryComponent
      title={"Интеграция с СМС-рассылками"}
      subtitle={"Персонализируйте общение с учениками и родителями, быстро информируйте \n их о событиях центра."}
      galleryView={"img"}
      offShadow={true}
      smallMargin={true}
      data={[
        {
          name: "mailings",
          title: "Массовые рассылки",
          content: "Достигайте максимальной скорости информирования клиентов: в несколько кликов делайте рассылки по всей базе, непродленным абонементам, конкретной группе или статусу.",
          image: require("../../images/screenshot/integration/mailings2.png"),
        },
        {
          name: "individual",
          title: "Индивидуальные рассылки",
          content: "Повышайте лояльность клиентов через внимание и заботу о них. Поздравьте ученика с Днем рождения, родителей с праздниками, поблагодарите после покупки.",
          image: require("../../images/screenshot/integration/individual2.png"),
        },
        {
          name: "various",
          title: "Разные сервисы",
          content: `
            Выберите один из четырех доступных в Параплан CRM сервисов. Операторы рассылок для РФ: 
            <a href='http://sms.ru/' target='_blank'>http://sms.ru/</a>,   
            <a href='https://smsaero.ru/' target='_blank'>https://smsaero.ru/</a>,   
            <a href='https://www.sms4b.ru/' target='_blank'>https://www.sms4b.ru/</a>. 
            Также доступен 1 оператор для Украины: 
            <a href='https://turbosms.ua/' target='_blank'>https://turbosms.ua/</a>. 
            
          `,
          image: require("../../images/screenshot/integration/various2.png"),
        },
      ]}
    />
    <AdditionalComponent
      title="Дополнительные интеграции"
    />
    <PricingComponent 
      descr="Стоимость интеграций по тарифной сетке."
    >
      Расширяйте возможности вашего бизнеса <br /> вместе с Параплан СRM
    </PricingComponent>
    <FeaturesComponent isFeatureList={true} current={"integration"} />
    <ContactFormComponent />
  </Layout>
)

export default IntegrationFeaturePage
