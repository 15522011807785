import React from "react"
import styles from "./additional-integration.module.scss"
import AboutComponent from "../../../../components/pages/main/about"

const AdditionalIntegration = ({ title }) => (
    <div className={styles.additionalIntegration}>
        <h2 className={styles.topTitle}>{title}</h2>
        <AboutComponent
            title='Интеграция с Tilda'
            description='Направьте поток лидов с вашего сайта на конструкторе Tilda в воронку продаж Параплан. Встройте Webhook на сайт, и он точно определит источник заявки и автоматически занесет ее в аналитику.'
            image={require("../../../../images/screenshot/integration/additional-integration-12.png")}
            smallMargin
            infoSize={"38%"}
        />
        <AboutComponent
            title='Прием оплаты онлайн'
            description='Подключите Личные кабинеты, которые интегрированы с Интернет-эквайрингом. Ваши клиенты смогут пополнять баланс онлайн в своем кабинете.'
            image={require("../../../../images/screenshot/integration/additional-integration-22.png")}
            reverse={true}
            smallMargin
            infoSize={"40%"}
        />
    </div>
)

export default AdditionalIntegration
