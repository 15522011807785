import React from "react"
import PricingComponent from "../../main/detail-pricing"
import styles from "./integration.module.scss"

const IntegrationPricing = ({ children, descr }) => (
    <div className={styles.integrationPricing}>
        <div className={`container ${styles.integrationContainer}`}>
            <h2 className={styles.title}>{children}</h2>
            <p className={styles.descr}>{descr}</p>
        </div>
        <PricingComponent integration mainPage={true} withOutPrice={true} />
    </div>
)

export default IntegrationPricing