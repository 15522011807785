import React from "react"
import AboutComponent from "../../../../components/pages/main/about"
import styles from "./integration-about.module.scss"

const FeaturesGridComponent = ({ title, subtitle }) => (
    <div className={styles.integrationAbout}>
        <div className={`container`}>
            <div className={styles.header}>
                <div className={styles.title}>{title}</div>
                <div className={styles.subtitle}>{subtitle}</div>
            </div>
            <AboutComponent
                title='Форма сбора заявок'
                description='Привлекайте новые лиды через виджет на сайте и в социальных сетях. Все заявки перед глазами в воронке продаж. '
                image={require("../../../../images/screenshot/integration/widgets-12.png")}
                reverse={true}
                smallMargin
            />
            <AboutComponent
                title='Онлайн-расписание с формой записи'
                description='Виджет соберет записи на занятия, а вы сконцентрируйтесь на главном - продаже абонементов и поддержке клиентов.'
                image={require("../../../../images/screenshot/integration/widgets-22.png")}
                smallMargin
                infoSize={"41%"}
            />
        </div>
    </div>
)

export default FeaturesGridComponent